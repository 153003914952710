@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "DM Sans", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  overflow-x: hidden;
}

@media screen and (min-width: 320px) {
  .offcanva-text {
    text-align: center !important;
  }
  /* .ant-picker {
    padding: 7px 30px 7px;
  } */
}

ul {
  list-style-type: disc;
}

.ant-picker {
  padding: 5px 20px 10px;
}

css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color: white !important;
  font-weight: 500;
}

.MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: white !important;
}

.poupbg {
  background: #e3e8fc !important;
}

.login-button{
  color: #2d53da !important;
  text-decoration: none !important;
  background: transparent;
  border: none;
  font-weight: 600;
}

.ant-btn {
  display: none;
}

.ant-picker-ok {
  .ant-btn {
    display: flex !important;
}
}

.ant-btn {
  background-color: blue !important;
  color: white !important;
}

.accordion-button {
  box-shadow: blue !important;
}
.accordion-button:not(.collapsed) {
  background-color: blue !important;
  box-shadow: blue !important;
  color: white;
  border-radius: 10px !important;
}

.ant-modal-content {
  padding: 0px !important;
  overflow: hidden !important;
}

.ant-modal-close {
  display: none !important;
}

.ant-picker-outlined {
  border-color: #95a2ae91 !important;
}

.successtick {
  position: absolute !important;
  top: 100px !important;
}

.content {
  text-align: center !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  font-family: "DM Sans", sans-serif !important;
}

.accordion-arrow {
  background-image: none !important;
  display: none;
}

.accordion-button::after {
  background-image: none !important;
  display: none;
}

.arrow {
  margin-left: auto !important;
}

.contain-margin {
  padding-left: 10%;
  padding-right: 10%;
}

.navbar-toggler {
  border: none !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

p {
  font-family: "DM Sans", sans-serif !important;
}

@media screen and (max-width: 768px) {
  .bgimage {
    padding-left: 0% !important;
    padding-right: 0% !important;
    padding-top: 0% !important;
  }

  .rounded-xl {
    border-radius: 0rem !important;
  }
}
